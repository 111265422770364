/**
 * @param {String} name `chalk` | `blue` | `blue-bale` | `white` (default)
 */
export const renderBackgroundColor = name => {
  if (name === "chalk") return "bg-yellow-100"
  if (name === "blue") return "bg-blue-200"
  if (name === "blue-bale") return "bg-blue-100"
  return "bg-white"
}

/**
 *
 * @param {Object} padding `"yes"` ||  `"no"`
 * @param {String} padding.paddingTop
 * @param {String} padding.paddingBottom
 */
export const renderPaddingClass = ({ paddingTop, paddingBottom } = {}) => {
  let result = ""
  if (paddingTop?.toLowerCase() === "no") result += "pt-0 "
  if (paddingBottom?.toLowerCase() === "no") result += "pb-0 "
  return result
}
