import React from "react"
import BlockSection from "../common/block"
import { renderBackgroundColor } from "../../utils/renderStyles"
import styled from "styled-components"
const TitleBlock = ({
  content,
  heading,
  backgroundColor,
  blockId,
  line,
  buttons,
}) => {
  return (
    <BlockSection
      id={blockId}
      className={`title-block  ${
        line === "yes" ? "has-line" : ""
      }  py-6 ${renderBackgroundColor(backgroundColor)}`}
    >
      <div className="container lg:px-2 px-4 mx-auto">
        <h2 className="title">{heading}</h2>
        {content ? (
          <Des
            className="title-block-text "
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : null}
        {buttons?.length && buttons[0]?.link !== null ? (
          <div className="flex items-center gap-2 mt-4 flex-wrap">
            {buttons.map(({ link }, index) =>
              link ? (
                <a
                  href={link.url || "#"}
                  className="btn btn-outline-primary"
                  key={index}
                >
                  {link.title}
                </a>
              ) : null
            )}
          </div>
        ) : null}
      </div>
    </BlockSection>
  )
}

export default TitleBlock

const Des = styled.div`
  p:last-child {
    margin: 0;
  }
`
