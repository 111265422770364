import { useStaticQuery, graphql } from "gatsby"

/**
 * Default limit: 10
 * @returns
 */
export default function useQueryRecentPosts() {
  return useStaticQuery(
    graphql`
      {
        allWpPost(sort: { order: DESC, fields: [date] }, limit: 10) {
          # pageInfo {
          #   currentPage
          #   hasPreviousPage
          #   pageCount
          #   itemCount
          #   perPage
          #   totalCount
          #   hasNextPage
          # }
          nodes {
            ...postFragment
          }
        }
      }
    `
  )
}
